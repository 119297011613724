import { default as Axios } from 'axios'
import qs from 'querystring'
import { useEffect, useState } from 'react'

export const Api = () => {
  const api = Axios.create({
    baseURL: '/api',
  })
  api.interceptors.response.use(
    (succes) => succes,
    (err) => {
      if (err.response && err.response.data) {
        throw { ...err.response.data, code: err.response.status }
      }
      throw err
    }
  )

  return api
}

export const useDataSet = (path, initialData = [], params = {}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)
  const [data, setData] = useState({
    data: initialData,
    take: 10,
    page: 1,
    total: 0,
    totalPage: 0,
  })

  const fetchData = async () => {
    setIsLoading(true)
    try {
      const q = qs.parse(location.search ? location.search.split('?')[1] : '')
      const { data } = await Api().get(
        path + '?' + qs.stringify({ ...q, ...params })
      )
      setData(data)
    } catch (e) {
      setError(e)
    }
    setIsLoading(false)
  }

  if (typeof window !== 'undefined') {
    useEffect(() => {
      fetchData()
      return () => {}
    }, [location.search, path])
  }

  return { data, extras: { error, isLoading, fetchData } }
}

export const getQueryParams = () => {
  if (typeof window !== 'undefined') {
    if (location.search) {
      return qs.parse(location.search.split('?')[1])
    }
  }
  return {} as any
}

export const toQueryParams = (obj) => {
  return '?' + qs.stringify(obj)
}

const fetcher = (url) =>
  Axios.create({ baseURL: '/api' })
    .get(url)
    .then((res) => res.data)

export default fetcher
